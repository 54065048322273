import P5Sketch from './P5Sketch'

function App() {
  return (
    <>
      <div id="overlay"></div>
      <P5Sketch/>
    </>
  );
}

export default App;
